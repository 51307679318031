import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
	console.log(to);
	console.log(from);

	if(to.query.returnUrl){
		let appid =
			"wx23406d34d48c253c"; //实际公众号appid，这里的appid对应的微信应用一定是绑定当前h5项目所在线上域名，也就是需要在微信开放平台绑定js安全域名
		let redirect_uri = encodeURIComponent(
			'https://auth.allnfts.cn' + to.fullPath
		);
		let url =
			"https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
			appid + "&redirect_uri=" + redirect_uri +
			"&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
		// 以snsapi_base为scope发起的网页授权，是用来获取进入页面的用户的openid的，并且是静默授权并自动跳转到回调页的.（静默的另一种：对于已关注公众号的用户，如果用户从公众号的会话或者自定义菜单进入本公众号的网页授权页，即使是scope为snsapi_userinfo，也是静默授权，用户无感知。）以snsapi_userinfo为scope发起的网页授权，是用来获取用户的基本信息的。但这种授权需要用户手动同意，并且由于用户同意过，所以无须关注，就可在授权后获取该用户的基本信息。
		let code = GetParam(window.location.href.split("#")[0], "code");
		if (!code) {
			window.location.href = url;
		} else {
			let redirctUrl=to.query.returnUrl;
			if(redirctUrl.indexOf('?') == -1){
				window.location.href = decodeURIComponent(to.query.returnUrl) + '?code=' + code
			}else{
				window.location.href = decodeURIComponent(to.query.returnUrl) + '&code=' + code
			}
		}
	}
	next();
})

const GetParam = function(url, code) {
	url = url + "";
	let regstr = "/(\\?|\\&)" + code + "=([^\\&]+)/";
	let reg = eval(regstr);
	//eval可以将 regstr字符串转换为 正则表达式
	let result = url.match(reg);
	if (result && result[2]) {
		return result[2];
	}
}

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
